import React from 'react';
import { SectionHeader } from './SectionHeader';
import { SectionContainer } from './SectionContainer';
import { Book } from './Book';

export function CurrentlyReading() {
    return (
        <SectionContainer>
            <SectionHeader header="I am currently reading..." />
            <Book
                title="The Almanack of Naval Ravikant: A Guide to Wealth and Happiness by Eric Jorgenson"
                photoUrl="/books/the-almanack-of-naval-ravikant.jpeg"
                amazonLink="https://amzn.to/37meMO8"
                description="This book was recommended to me by a coworker at LinkedIn. I've read the infamous tweetstorm before so thought 
                this might be a good read also even though it wasn't written by Naval himself. Funny that I'm currently reading that as 
                I build out this site hoping to make money out it haha! Guess it had some sort of affect on me already! 😂"
            />
        </SectionContainer>
    );
}
