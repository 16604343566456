import { Box } from '@mui/material';
import React from 'react';
import { CustomLink } from '../Components/CustomLink';
import { NavBar } from '../Components/NavBar';

export function NavPage() {
    return (
        <Box className="container" sx={{ height: '100vh' }} display="flex" flexDirection="column">
            <NavBar hideLinks />
            <CustomLink to="/">About</CustomLink>
            <CustomLink to="/books">Books</CustomLink>
            <CustomLink to="/recommendations">Recommendations</CustomLink>
            <CustomLink to="/tech">Tech</CustomLink>
            <CustomLink to="/products">Products</CustomLink>
            <CustomLink to="/blog">Blog</CustomLink>
        </Box>
    );
}