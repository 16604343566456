import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../Components/Footer';
import { NavBar } from '../Components/NavBar';
import { ScrollToTop } from '../Components/ScrollToTop';

export function HomePage() {
    return (
        <Box className="container">
            <ScrollToTop />
            <NavBar />
            <Outlet />
            <Grid container justifyContent="center">
                <Footer />
            </Grid>
        </Box>
    );
}