import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';

export function CustomLink({ children, to }) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link
            component={RouterLink}
            underline={match ? 'always' : 'hover'}
            color={match ? 'primary' : 'inherit'}
            to={to}
            sx={{
                textUnderlineOffset: '8px',
                textDecorationThickness: '2px !important',
                padding: '8px',
                fontWeight: 500
            }}
        >
            {children}
        </Link>
    );
}