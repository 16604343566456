import { Box } from '@mui/system';
import React from 'react';
import { CurrentProjects } from '../Components/CurrentProjects';
import { Hero } from '../Components/Hero';
import { LastThreeReads } from '../Components/LastThreeReads';
import { CurrentlyReading } from '../Components/CurrentlyReading';

export function AboutPage() {
    return (
        <Box>
            <Hero />
            <CurrentProjects />
            <CurrentlyReading />
            <LastThreeReads />
        </Box>
    )
}