import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { BOX_SHADOW } from '../constants';


export function Book({ title, photoUrl, description, amazonLink }) {
    return (
        <Grid display="flex" marginBottom={{ xs: '16px', sm: '40px' }} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box marginBottom={{ xs: '16px', sm: '0' }} marginRight={{ xs: '0', sm: '80px' }} display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <Link href={amazonLink}>
                    <img
                        src={photoUrl}
                        alt={title}
                        role="presentation"
                        style={{
                            width: '200px', boxShadow: BOX_SHADOW, borderRadius: '8px'
                        }}
                    />
                </Link>
            </Box>
            <Box marginBottom={{ xs: '16px', sm: '0' }}>
                <Link href={amazonLink}>
                    <Typography variant="h6" component="h4" marginBottom={{ xs: '16px', sm: '24px' }}>{title}</Typography>
                </Link>
                <Typography variant="subtitle1">{description}</Typography>
            </Box>
        </Grid>
    );
}
