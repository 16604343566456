import './App.css';
import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import { HomePage } from './Pages/Homepage';
import { AboutPage } from './Pages/AboutPage';
import { UnderContructionPage } from './Pages/UnderContructionPage';
import { NavPage } from './Pages/NavPage';

const theme = responsiveFontSizes(createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join(',')
        },
        button: {
            textTransform: 'none'
        }
    },
}));

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />}>
                        <Route index element={<AboutPage />} />
                        <Route path="books" element={<UnderContructionPage />} />
                        <Route path="recommendations" element={<UnderContructionPage />} />
                        <Route path="tech" element={<UnderContructionPage />} />
                        <Route path="products" element={<UnderContructionPage />} />
                        <Route path="blog" element={<UnderContructionPage />} />
                    </Route>
                    <Route path="/nav" element={<NavPage />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
