import React from 'react';
import { Grid, IconButton } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CustomLink } from './CustomLink';

interface NavBarProps {
    hideLinks?: boolean;
}

export function NavBar({ hideLinks }: NavBarProps) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box component="nav" style={{ width: '100%' }} marginBottom={{ xs: "0px", sm: "80px" }}>
            <Grid item xs={12} justifyContent="space-between" display="flex" height={80} alignItems="center">
                <Box>
                    <RouterLink to="/">
                        <img alt="logo" src="/favicon-32x32.png"></img>
                    </RouterLink>
                </Box>

                {!hideLinks && <Box>
                    {!matches && (
                        <RouterLink to="/nav">
                            <IconButton>
                                <MenuIcon htmlColor="black" />
                            </IconButton>
                        </RouterLink>
                    )}
                    {matches && (
                        <>
                            <CustomLink to="/">About</CustomLink>
                            <CustomLink to="/books">Books</CustomLink>
                            <CustomLink to="/recommendations">Recommendations</CustomLink>
                            <CustomLink to="/tech">Tech</CustomLink>
                            <CustomLink to="/products">Products</CustomLink>
                            <CustomLink to="/blog">Blog</CustomLink>
                        </>
                    )}
                </Box>}
            </Grid>
        </Box>
    );
}
