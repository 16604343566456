import React from 'react';
import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export function Footer() {
    return (
        <footer style={{ width: '100%' }}>
            <Grid item xs={12} justifyContent="space-between" display="flex" height={80} alignItems="center">
                <Box>
                    © Damon Ye 2022.
                </Box>
                <Box>
                    <Link href="https://www.linkedin.com/in/damonye">
                        <LinkedInIcon fontSize="large" />
                    </Link>
                    <Link href="mailto:damonye91@gmail.com">
                        <EmailOutlinedIcon fontSize="large" />
                    </Link>
                </Box>
            </Grid>
        </footer>
    );

}