import React from 'react';
import { Card, CardActionArea, CardContent, Grid, Link, Paper, Typography } from '@mui/material';
import { SectionHeader } from './SectionHeader';
import { SectionContainer } from './SectionContainer';

interface ProjectProps {
    icon: string;
    title: string;
    description: string;
    href?: string;
}

function Project({ icon, title, description, href }: ProjectProps) {
    return (
        <Grid item xs={12} sm={4}>
            <Card sx={{
                '&:hover': {
                    transform: 'translateY(-3px)'
                },
                transition: 'color 0.3s, transform 0.3s !important',
                height: '100%',
                boxShadow: 'rgb(0 0 0 / 18%) 0px 4px 8px',
            }}>
                <CardActionArea component={href ? Link : Paper} href={href} sx={{ height: '100%' }} disabled={!href} target="_blank" rel="noopener">
                    <CardContent sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Typography variant="h2" component="h4">{icon}</Typography>
                        <Typography variant="h4">{title}</Typography>
                        <Typography variant="body1" component="h5" textAlign={'center'}>{description}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export function CurrentProjects() {
    return (
        <SectionContainer>
            <SectionHeader header="I am currently working on..." />
            <Grid container display="flex" spacing={{ xs: 3, sm: 2, md: 6 }}>
                {/* <Project icon="🧮" title="401k calculator" description="Calculate your monthly 401k contribution" href="https://401kcalculators.com" /> */}
                <Project icon="💻" title="New job!" description="Just started at LinkedIn! Settling in and onboarding." />
                <Project icon="☺️" title="This site!" description="Working on this site in my free time just for fun and hopefully some small income to pay for my board games 😂" />
            </Grid>
        </SectionContainer>
    );
}
