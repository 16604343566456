import React from 'react';
import { Link } from 'react-router-dom';
import { SectionHeader } from './SectionHeader';
import { SectionContainer } from './SectionContainer';
import { Book } from './Book';

export function LastThreeReads() {
    return (
        <SectionContainer>
            <SectionHeader header="I just finished reading..." />
            <Book
                photoUrl="/books/the-convenience-store-woman.jpeg"
                amazonLink="https://amzn.to/3uawJb2"
                title="The Convenience Store Woman by Sayaka Murata"
                description="I forget where I originally got the recommendation for this book but I saw the cute book cover in a bookstore 
                somewhere in mini Chinatown in San Francisco as I was killing time before my flight back to New York. 
                I had to pick it up since it seemed like a perfect read for the flight. The book follows Keiko Furukara, 36 year old woman that works at a 
                conveience store as she deals with work culture and societal pressures on women. Awesome book!"
            />
            <Book
                photoUrl="/books/dear-girls.jpeg"
                amazonLink="https://amzn.to/3DEsgkj"
                title="Dear Girls: Intimate Tales, Untold Secrets & Advice for Living Your Best Life by Ali Wong"
                description="Ali Wong!!! She's so damn funny. I loved her Netflix specials and I loved this book! Very cool reading about 
                how Ali Wong started her career and nuggets of wisdom she'd want to leave for her daughters."
            />
            <Book
                photoUrl="/books/die-with-zero.jpeg"
                amazonLink="https://amzn.to/3uYaSmm"
                title="Die with Zero: Getting All You Can from Your Money and Your Life by Bill Perkins"
                description="I love personal finance so when this book came out that was the exact opposite of what I practice
                 I had to get it. This book definitely changed my mind about some things about saving and investing. I'm not sure if I 
                 would go as far as to die with zero but I've decided to be less frugal. There are definitely some things I disagree with 
                 in the book but it's a great book for people who are into personal finance and the financial independence retire early(FIRE) movement."
            />

            <Link to="/books">See more book recommendations</Link>
        </SectionContainer>
    );
}


export function LatestReads() {
    return (
        <>
            Convenience Store Woman
            Dear Girls
            Die with Zero
            Think Again

            Favorite Book of the Year
            Project Hail Mary

            Favorite Book of all time
            Atomic Habits
        </>
    )
}
