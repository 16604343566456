import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { SectionContainer } from './SectionContainer';
import { BOX_SHADOW } from '../constants';

export function Hero() {
    return (
        <SectionContainer>
            <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
                    <Typography variant="h4" component="h6" fontWeight={500}>Hello there! 👋🏼</Typography>
                    <Typography variant="subtitle1" component="h1" marginBottom={{ xs: "8px", sm: "12px" }}>
                        My name is Damon! I'm a <Link href="https://linkedin.com/in/damonye">senior software engineer</Link> based in New York City.
                        My current gig is at <Link href="https://linkedin.com">LinkedIn</Link>!
                        Before that I was at <Link href="https://amazon.com">Amazon</Link> and
                        even before that I was at <Link href="https://grubhub.com">Grubhub</Link>!
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        I spend most of my time playing tons of board games &#x1f3b2;&nbsp;, reading &#x1f4da;&nbsp;, playing
                        tennis &#x1f3be;&nbsp;, traveling to new places &#x1f30e;&nbsp;, trying new foods
                        &#x1f371;&nbsp;, tinkering with everything &#x1f528;&nbsp;, and
                        watching a seemingly endless amount of content &#x1f4fa;&nbsp;.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} display="flex" order={{ xs: 1, sm: 2 }} marginBottom={{ xs: '28px', sm: '0' }} justifyContent={{ xs: 'center', sm: 'auto' }}>
                    <img
                        className="header-photo"
                        src="/profile-picture.png"
                        alt="headshot of Damon Ye"
                        role="presentation"
                        style={{ borderRadius: '50%', height: '300px', width: '300px', boxShadow: BOX_SHADOW }}
                    />
                </Grid>
            </Grid>
        </SectionContainer>
    )
}