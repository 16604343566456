import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/system";

export function UnderContructionPage() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const height = matches ? '300' : '148'

    return (
        <Grid height={`calc(100vh - ${height}px)`}>
            Hello there!  Sorry this page is still under contruction! 🚧 👷🏼
        </Grid>
    );
}